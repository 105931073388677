import { signIn } from "next-auth/react";
import { useEffect } from "react";

const Signin = () => {
	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const callbackUrl = urlParams.get("callbackUrl") || "/";

		signIn("", { callbackUrl });
	}, []);

	return null;
};

export default Signin;
